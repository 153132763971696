
* {
    transition: color 0.2s ease, border-color 0.2s ease, background-color 0.2s ease-in;
}

*, *:before,*:after {
    box-sizing: border-box;
}

button {
    appearance: none;
    border-width: 1px;
    border-style: solid;
}

p { 
    max-width: 80ch;
    font-size: 14px;
    line-height: 1.5;
}

label { 
    font-size: 12px;
}
